import React from 'react'

import BigNumber from 'bignumber.js'
import { BalanceBlock } from '../common'
import { ownership } from '../../utils/number'
import styles from './CouponMarket.module.css'

type CouponMarketHeaderProps = {
  debt: BigNumber
  supply: BigNumber
  coupons: BigNumber
  premium: BigNumber
  redeemable: BigNumber
}

const CouponMarketHeader = ({
  debt,
  supply,
  coupons,
  premium,
  redeemable,
}: CouponMarketHeaderProps) => (
  <div className={styles.header_container}>
    <div className={styles.header_content}>
      <BalanceBlock
        asset="Debt Ratio"
        balance={ownership(debt, supply)}
        suffix={'%'}
      />
    </div>
    <div className={styles.header_content}>
      <BalanceBlock asset="Total Debt" balance={debt} suffix={'T'} />
    </div>
    <div className={styles.header_content}>
      <BalanceBlock asset="Coupons" balance={coupons} />
    </div>
    <div className={styles.header_content}>
      <BalanceBlock
        asset="Premium"
        balance={premium.multipliedBy(100)}
        suffix={'%'}
      />
    </div>
    <div className={styles.header_content}>
      <BalanceBlock asset="Redeemable" balance={redeemable} />
    </div>
  </div>
)

export default CouponMarketHeader
