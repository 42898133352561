import { Chain, Wallet } from "@rainbow-me/rainbowkit";
import { SafeConnector } from "@gnosis.pm/safe-apps-wagmi";

export interface MyWalletOptions {
  chains: Chain[];
}
const SafeRainbowConnector = ({ chains }: MyWalletOptions): Wallet => ({
  id: "gnosis-safe",
  name: "Gnosis Safe",
  iconUrl: "/logo/safe.svg",
  iconBackground: "#fff",
  downloadUrls: {
    browserExtension:
      'https://gnosis-safe.io/',
    android: 'https://gnosis-safe.io/',
    ios: 'https://gnosis-safe.io/',
    qrCode: 'https://gnosis-safe.io/',
  },
  createConnector: () => {
    const connector = new SafeConnector({ chains });
    return {
      connector,

    };
  },
});
export default SafeRainbowConnector;