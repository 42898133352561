import React from 'react'
import { NavLink } from 'react-router-dom'

import { LinkBase, useTheme } from '@aragon/ui'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import styles from './NavBar.module.css'
import { useAccount, useProvider, useSigner } from 'wagmi'

type NavbarProps = {
  hasWeb3: boolean
  user: string
  setUser: Function
}

function NavBar({ hasWeb3, user, setUser }: NavbarProps) {
  const currentTheme = useTheme()
  const { address, isConnected } = useAccount()
  if (isConnected) {
    setUser(address)
  }

  const logoUrl = `./logo/titanium-${
    currentTheme._name === 'light' ? 'black' : 'white'
  }.svg`

  return (
    <>
      <div
        style={{
          borderTop: '1px solid ' + currentTheme.border,
          backgroundColor: 'none',
          textAlign: 'center',
          height: '128px',
          width: '100%',
          fontSize: '14px',
        }}
      >
        <div className={styles.nav_wrapper}>
          <div style={{ display: 'flex' }}>
            <NavLink
              to="/"
              external={false}
              component={LinkBase}
              style={{ marginRight: '16px', height: '80px' }}
            >
              <img src={logoUrl} height="80px" alt="Titanium" />
            </NavLink>
          </div>
          <div style={{ display: 'flex' }}>
            <div className={styles.hide_links}>
              <LinkButton title="Forge" to="/forge/" />
              <LinkButton title="Liquidity" to="/pool/" />
              <LinkButton title="Regulation" to="/regulation/" />
              <LinkButton title="Epoch" to="/epoch/" />
              <LinkButton title="Trade" to="/trade/" />
              <LinkButton title="Coupons" to="/coupons/" />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <ConnectButton chainStatus="none" showBalance={false} />
          </div>
        </div>
      </div>
    </>
  )
}

type linkButtonProps = {
  title: string
  to: string
}

function LinkButton({ title, to }: linkButtonProps) {
  return (
    <NavLink
      to={to}
      component={LinkBase}
      external={false}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        marginLeft: '8px',
        marginRight: '8px',
        height: '40px',
        opacity: 0.5,
      }}
      activeStyle={{ opacity: 1 }}
    >
      <span style={{ display: 'block', padding: '1%', fontSize: '17px' }}>
        {title}
      </span>
    </NavLink>
  )
}

export default NavBar
