import React, { useState } from 'react'
import {
  Box,
  Button,
  IconCirclePlus,
  IconCircleMinus,
  IconCaution,
} from '@aragon/ui'
import BigNumber from 'bignumber.js'
import { BalanceBlock, MaxButton } from '../common/index'
import { bondPool, unbondPool } from '../../utils/web3'
import { isPos, toBaseUnitBN } from '../../utils/number'
import { UNI } from '../../constants/tokens'
import BigNumberInput from '../common/BigNumberInput'
import TextBlock from '../common/TextBlock'
import styles from './Pool.module.css'

type BondUnbondProps = {
  poolAddress: string
  staged: BigNumber
  bonded: BigNumber
  status: number
  lockup: number
}

function BondUnbond({
  poolAddress,
  staged,
  bonded,
  status,
  lockup,
}: BondUnbondProps) {
  const [bondAmount, setBondAmount] = useState(new BigNumber(0))
  const [unbondAmount, setUnbondAmount] = useState(new BigNumber(0))

  return (
    <Box className={styles.box_custom_style} heading="Forge">
      <div className={styles.wrapper}>
        {/* Total bonded */}

        <div style={{ display: 'flex' }}>
          <div style={{ whiteSpace: 'nowrap' }}>
            <BalanceBlock asset="Bonded" balance={bonded} suffix={'T-3CRV'} />
          </div>
        </div>
        <div className={styles.button_wrapper}>
          {/* Bond UNI-V2 within Pool */}
          <div style={{ display: 'flex' }}>
            <div style={{ width: '60%', minWidth: '6em' }}>
              <>
                <BigNumberInput
                  adornment="T-3CRV"
                  value={bondAmount}
                  setter={setBondAmount}
                />
                <MaxButton
                  onClick={() => {
                    setBondAmount(staged)
                  }}
                />
              </>
            </div>
            <div style={{ width: '40%', minWidth: '7em' }}>
              <Button
                wide
                icon={status === 0 ? <IconCirclePlus /> : <IconCaution />}
                label="Bond"
                onClick={() => {
                  bondPool(
                    poolAddress,
                    toBaseUnitBN(bondAmount, UNI.decimals),
                    (hash) => setBondAmount(new BigNumber(0))
                  )
                }}
                disabled={poolAddress === '' || !isPos(bondAmount)}
              />
            </div>
          </div>
          <div style={{ flexBasis: '2%' }} />
          {/* Unbond UNI-V2 within Pool */}
          <div style={{ display: 'flex' }}>
            <div style={{ width: '60%', minWidth: '6em' }}>
              <>
                <BigNumberInput
                  adornment="T-3CRV"
                  value={unbondAmount}
                  setter={setUnbondAmount}
                />
                <MaxButton
                  onClick={() => {
                    setUnbondAmount(bonded)
                  }}
                />
              </>
            </div>
            <div style={{ width: '40%', minWidth: '7em' }}>
              <Button
                wide
                icon={status === 0 ? <IconCircleMinus /> : <IconCaution />}
                label="Unbond"
                onClick={() => {
                  unbondPool(
                    poolAddress,
                    toBaseUnitBN(unbondAmount, UNI.decimals),
                    (hash) => setUnbondAmount(new BigNumber(0))
                  )
                }}
                disabled={poolAddress === '' || !isPos(unbondAmount)}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', paddingTop: '2%', textAlign: 'center' }}>
        <span style={{ opacity: 0.5 }}>
          {' '}
          Bonding events will restart the lockup timer (Exit lockup: {
            lockup
          }{' '}
          epochs)
        </span>
      </div>
    </Box>
  )
}

export default BondUnbond
