import React, { useState } from 'react'
import { Box, Button, IconArrowUp, IconCirclePlus, IconLock } from '@aragon/ui'
import BigNumber from 'bignumber.js'
import { BalanceBlock, MaxButton, PriceSection } from '../common/index'
import { approve, providePool } from '../../utils/web3'
import { isPos, toBaseUnitBN, toTokenUnitsBN } from '../../utils/number'
import { ESD, USDC } from '../../constants/tokens'
import { MAX_UINT256 } from '../../constants/values'
import BigNumberInput from '../common/BigNumberInput'
import styles from './Pool.module.css'
import { parseEther } from 'ethers/lib/utils'

type ProvideProps = {
  poolAddress: string
  user: string
  rewarded: BigNumber
  pairBalanceESD: BigNumber
  pairBalanceUSDC: BigNumber
  userUSDCBalance: BigNumber
  userUSDCAllowance: BigNumber
  status: number
}

function Provide({
  poolAddress,
  user,
  rewarded,
  pairBalanceESD,
  pairBalanceUSDC,
  userUSDCBalance,
  userUSDCAllowance,
  status,
}: ProvideProps) {
  const [provideAmount, setProvideAmount] = useState(new BigNumber(0))
  const [usdcAmount, setUsdcAmount] = useState(new BigNumber(0))

  const USDCToESDRatio = pairBalanceUSDC.isZero()
    ? new BigNumber(1)
    : pairBalanceUSDC.div(pairBalanceESD)

  const onChangeAmountESD = (amountESD) => {
    if (!amountESD) {
      setProvideAmount(new BigNumber(0))
      setUsdcAmount(new BigNumber(0))
      return
    }

    const amountESDBN = new BigNumber(amountESD)
    setProvideAmount(amountESDBN)
    setUsdcAmount(amountESDBN)
  }

  return (
    <Box className={styles.box_custom_style} heading="Provide">
      {userUSDCAllowance.comparedTo(MAX_UINT256.dividedBy(2)) > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* total rewarded */}
          <div style={{ flexBasis: '32%' }}>
            <BalanceBlock asset="Rewarded" balance={rewarded} suffix={'T'} />
          </div>
          <div style={{ flexBasis: '33%' }}>
            <BalanceBlock
              asset="3CRV Balance"
              balance={userUSDCBalance}
              suffix={'3CRV'}
            />
          </div>
          <div style={{ flexBasis: '2%' }} />
          {/* Provide liquidity using Pool rewards */}
          <div style={{ flexBasis: '33%', paddingTop: '2%' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '60%', minWidth: '6em' }}>
                <>
                  <BigNumberInput
                    adornment="T"
                    value={provideAmount}
                    setter={onChangeAmountESD}
                    disabled={status === 1}
                  />
                  {/* <PriceSection
                    label="Requires "
                    amt={usdcAmount}
                    symbol=" 3CRV"
                  /> */}
                  <MaxButton
                    onClick={() => {
                      onChangeAmountESD(rewarded)
                    }}
                  />
                </>
              </div>
              <div style={{ width: '40%', minWidth: '6em' }}>
                <Button
                  wide
                  icon={
                    poolAddress === '' ||
                    status !== 0 ||
                    !isPos(provideAmount) ||
                    usdcAmount.isGreaterThan(userUSDCBalance) ? (
                      <IconLock />
                    ) : (
                      <IconArrowUp />
                    )
                  }
                  label="Provide"
                  onClick={() => {
                    providePool(
                      poolAddress,
                      parseEther(provideAmount.toString()),
                      (hash) => setProvideAmount(new BigNumber(0))
                    )
                  }}
                  disabled={
                    poolAddress === '' ||
                    status !== 0 ||
                    !isPos(provideAmount) ||
                    usdcAmount.isGreaterThan(userUSDCBalance)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* total rewarded */}
          <div style={{ flexBasis: '32%' }}>
            <BalanceBlock asset="Rewarded" balance={rewarded} suffix={'T'} />
          </div>
          <div style={{ flexBasis: '33%' }}>
            <BalanceBlock
              asset="3CRV Balance"
              balance={userUSDCBalance}
              suffix={'3CRV'}
            />
          </div>
          <div style={{ flexBasis: '2%' }} />
          {/* Approve Pool to spend USDC */}
          <div style={{ flexBasis: '33%', paddingTop: '2%' }}>
            <Button
              wide
              icon={<IconCirclePlus />}
              label="Approve"
              onClick={() => {
                approve(USDC.addr, poolAddress)
              }}
              disabled={poolAddress === '' || user === ''}
            />
          </div>
        </div>
      )}
      <div style={{ width: '100%', paddingTop: '2%', textAlign: 'center' }}>
        <span style={{ opacity: 0.5 }}>
          {' '}
          Zap your rewards directly to LP by providing equal 3CRV{' '}
        </span>
      </div>
    </Box>
  )
}

export default Provide
