import React, { useState, useEffect } from 'react'
import { LinkBase, Box } from '@aragon/ui'

import BigNumber from 'bignumber.js'
import { getInstantaneousPrice, getTokenBalance } from '../../utils/infura'
import { toTokenUnitsBN } from '../../utils/number'

import TradePageHeader from './Header'
import { ESD, UNI, USDC } from '../../constants/tokens'
import IconHeader from '../common/IconHeader'
import styles from './Trade.module.css'

function UniswapPool({ user }: { user: string }) {
  const [pairBalanceESD, setPairBalanceESD] = useState(new BigNumber(0))
  const [pairBalanceUSDC, setPairBalanceUSDC] = useState(new BigNumber(0))
  const [price, setPrice] = useState<any>(new BigNumber(0))

  useEffect(() => {
    let isCancelled = false

    async function updateUserInfo() {
      try {
        const [pairBalanceESDStr, pairBalanceUSDCStr, price] =
          await Promise.all([
            getTokenBalance(ESD.addr, UNI.addr),
            getTokenBalance(USDC.addr, UNI.addr),
            getInstantaneousPrice(),
          ])

        if (!isCancelled) {
          setPairBalanceESD(toTokenUnitsBN(pairBalanceESDStr, ESD.decimals))
          setPairBalanceUSDC(toTokenUnitsBN(pairBalanceUSDCStr, USDC.decimals))
          setPrice(price)
        }
      } catch (error) {
        console.log(error)
      }
    }

    updateUserInfo()
    const id = setInterval(updateUserInfo, 15000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [user])

  return (
    <>
      <IconHeader icon={<i className="fas fa-exchange-alt" />} text="Trade" />

      <TradePageHeader
        pairBalanceESD={pairBalanceESD}
        pairBalanceUSDC={pairBalanceUSDC}
        price={price}
        uniswapPair={UNI.addr}
      />

      <div className={styles.box_container}>
        <div>
          <MainButton
            title="Info"
            description="View T-3CRV pool stats."
            icon={<i className="fas fa-chart-area" />}
            href="https://curve.fi/#/ethereum/pools/factory-v2-229/swap"
          />
        </div>

        <div>
          <MainButton
            title="Trade"
            description="Trade T tokens."
            icon={<i className="fas fa-exchange-alt" />}
            href="https://curve.fi/#/ethereum/pools/factory-v2-229/swap"
          />
        </div>

        <div>
          <MainButton
            title="Supply"
            description="Supply and redeem liquidity."
            icon={<i className="fas fa-water" />}
            href="https://curve.fi/#/ethereum/pools/factory-v2-229/deposit"
          />
        </div>
      </div>
    </>
  )
}

type MainButtonProps = {
  title: string
  description: string
  icon: any
  href: string
}

function MainButton({ title, description, icon, href }: MainButtonProps) {
  return (
    <LinkBase href={href} style={{ width: '100%' }}>
      <Box>
        <div style={{ padding: 10, fontSize: 18 }}>{title}</div>
        <span style={{ fontSize: 48 }}>{icon}</span>
        {/*<img alt="icon" style={{ padding: 10, height: 64 }} src={iconUrl} />*/}
        <div style={{ paddingTop: 5, opacity: 0.5 }}> {description} </div>
      </Box>
    </LinkBase>
  )
}

export default UniswapPool
