import React, { useEffect, useState } from 'react'

import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { Main, Layout } from '@aragon/ui'
import { updateModalMode } from './utils/web3'
import { storePreference, getPreference } from './utils/storage'
import NavBar from './components/NavBar'
import HomePage from './components/HomePage'
import Trade from './components/Trade/index'
import Footer, { FooterLink } from './components/Footer'
import Wallet from './components/Wallet'
import EpochDetail from './components/EpochDetail'
import CouponMarket from './components/CouponMarket'
import Governance from './components/Governance'
import Candidate from './components/Candidate'
import Regulation from './components/Regulation'
import Pool from './components/Pool'
import HomePageNoWeb3 from './components/HomePageNoWeb3'
import styles from './App.module.css'
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi'
import {
  RainbowKitProvider,
  connectorsForWallets,
  lightTheme,
  midnightTheme,
  getDefaultWallets,
} from '@rainbow-me/rainbowkit'
import { alchemyProvider } from 'wagmi/providers/alchemy'
// import { publicProvider } from 'wagmi/providers/public'
import '@rainbow-me/rainbowkit/styles.css'
import { ledgerWallet, trustWallet } from '@rainbow-me/rainbowkit/wallets'
import SafeRainbowConnector from './utils/SafeConnector'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: `https://eth-mainnet-public.unifra.io`,
      }),
      priority: 3,
    }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://eth-rpc.gateway.pokt.network`,
      }),
      priority: 1,
    }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://eth-mainnet.public.blastapi.io`,
      }),
      priority: 3,
    }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://cloudflare-eth.com`,
      }),
      priority: 0,
    }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://ethereum.publicnode.com`,
      }),
      priority: 1,
    }),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://rpc.ankr.com/eth`,
      }),
      priority: 0,
    }),
  ]
)

const connectors = connectorsForWallets([
  ...getDefaultWallets({
    appName: 'Titanium Dollar',
    chains,
  }).wallets,
  {
    groupName: 'Others',
    wallets: [
      ledgerWallet({ chains }),
      trustWallet({ chains }),
      SafeRainbowConnector({ chains }),
    ],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

function App() {
  const storedTheme = getPreference('theme', 'light')

  const [hasWeb3, setHasWeb3] = useState(false)
  const [user, setUser] = useState('') // the current connected user
  const [theme, setTheme] = useState(storedTheme)

  const updateTheme = (newTheme: string) => {
    setTheme(newTheme)
    updateModalMode(newTheme)
    storePreference('theme', newTheme)
  }

  useEffect(() => {
    let isCancelled = false

    async function updateUserInfo() {
      if (!isCancelled) {
        // @ts-ignore
        setHasWeb3(typeof window.ethereum !== 'undefined')
      }
    }

    updateUserInfo()
    const id = setInterval(updateUserInfo, 30000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [user])

  return (
    <Router>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          modalSize="compact"
          chains={chains}
          theme={{
            ...(theme == 'light' ? lightTheme() : midnightTheme()),
            colors: {
              ...(theme == 'light' ? lightTheme() : midnightTheme()).colors,
              accentColor: theme == 'light' ? '#fff' : '#35425e',
              modalBackground: theme == 'light' ? '#fff' : '#293248',
              actionButtonSecondaryBackground:
                theme == 'light' ? '#fff' : '#35425e',
              accentColorForeground: theme == 'light' ? '#000' : '#fff',
              connectButtonText: theme == 'light' ? '#000' : '#fff',
              connectButtonBackground: theme == 'light' ? '#fff' : '#35425e',
            },
            fonts: {
              body: '00-starmap-truetype-regular\n',
            },
            radii: {
              ...(theme == 'light' ? lightTheme() : midnightTheme()).radii,
              menuButton: '4px',
              modal: '4px',
              connectButton: '4px',
              actionButton: '4px',
            },
          }}
        >
          <Main
            assetsUrl={`${process.env.PUBLIC_URL}/aragon-ui/`}
            theme={theme}
            layout={false}
          >
            <NavBar hasWeb3={hasWeb3} user={user} setUser={setUser} />
            <Layout>
              <Switch>
                <Route path="/forge/:override">
                  <Wallet user={user} />
                </Route>
                <Route path="/forge/">
                  <Wallet user={user} />
                </Route>
                <Route path="/epoch/">
                  <EpochDetail user={user} />
                </Route>
                <Route path="/coupons/:override">
                  <CouponMarket user={user} />
                </Route>
                <Route path="/coupons/">
                  <CouponMarket user={user} />
                </Route>
                <Route path="/governance/candidate/:candidate">
                  <Candidate user={user} />
                </Route>
                <Route path="/governance/">
                  <Governance user={user} />
                </Route>
                <Route path="/trade/">
                  <Trade user={user} />
                </Route>
                <Route path="/regulation/">
                  <Regulation user={user} />
                </Route>
                <Route path="/pool/:override">
                  <Pool user={user} />
                </Route>
                <Route path="/pool/">
                  <Pool user={user} />
                </Route>
                <Route path="/">
                  <HomePage user={user} />
                </Route>
              </Switch>
            </Layout>
            <div style={{ height: '128px', width: '100%' }} />
            <Footer
              className={styles.footer}
              hasWeb3={hasWeb3}
              theme={theme}
              updateTheme={updateTheme}
            />
          </Main>
        </RainbowKitProvider>
      </WagmiConfig>
    </Router>
  )
}

export default App
