import React, { useEffect, useState } from 'react'
import { DataView, useTheme } from '@aragon/ui'

import {
  getAllRegulations,
  getEpoch,
  getTotalDebt,
  getTwapPrice,
} from '../../utils/infura'
import { ESD, ESDS } from '../../constants/tokens'
import { formatBN, toTokenUnitsBN } from '../../utils/number'
import BigNumber from 'bignumber.js'
import styles from './Regulation.module.css'
import { parseEther } from 'ethers/lib/utils'

type RegulationHistoryProps = {
  user: string
}

type Regulation = {
  type: string
  data: RegulationEntry
  bootstrap?: true
  totalDebt?: BigNumber
  twapPrice?: string
}

type RegulationEntry = {
  epoch: string
  price: string
  deltaRedeemable: string
  deltaDebt: string
  deltaBonded: string
}

function formatPrice(type, data, twapPrice) {
  // console.log(
  //   data.epoch.toString(),
  //   data.price.toString(),
  //   data.price.sub('978297737593846773').toString(),
  //   console.log(data.lessDebt.toString())
  // )
  if (type === 'INCREASE') {
    return `+${formatBN(
      toTokenUnitsBN(data.price, ESD.decimals).minus('0.978297737593846773'),
      3
    )}`
  }

  if (type === 'DECREASE') {
    return `${formatBN(
      toTokenUnitsBN(data.price, ESD.decimals).minus('0.978297737593846773'),
      3
    )}`
  }

  return type === 'NEUTRAL'
    ? `${formatBN(
        toTokenUnitsBN(twapPrice, ESD.decimals).minus('0.978297737593846773'),
        3
      )}`
    : formatBN(toTokenUnitsBN(data.price, ESD.decimals), 3)
}

function formatDeltaRedeemable(type, data) {
  return type === 'INCREASE'
    ? '+' + formatBN(toTokenUnitsBN(data.newRedeemable, ESD.decimals), 2)
    : '+0.00'
}

function formatDeltaDebt(type, data, totalDebt) {
  if (totalDebt?.isZero()) {
    return '0.00'
  }
  return type === 'INCREASE'
    ? '-' + formatBN(toTokenUnitsBN(data.lessDebt, ESD.decimals), 2)
    : type === 'DECREASE'
    ? '+' + formatBN(toTokenUnitsBN(data.newDebt, ESD.decimals), 2)
    : '+0.00'
}

function formatDeltaBonded(type, data) {
  return type === 'INCREASE'
    ? '+' + formatBN(toTokenUnitsBN(data.newBonded, ESD.decimals), 2)
    : '+0.00'
}

function renderEntry({
  type,
  data,
  totalDebt,
  twapPrice,
}: Regulation): string[] {
  return [
    data.epoch,
    formatPrice(type, data, twapPrice),
    formatDeltaRedeemable(type, data),
    formatDeltaDebt(type, data, totalDebt),
    formatDeltaBonded(type, data),
  ]
}

function RegulationHistory({ user }: RegulationHistoryProps) {
  const currentTheme = useTheme()
  const [epoch, setEpoch] = useState(0)
  const [regulations, setRegulations] = useState<Regulation[]>([])
  const [page, setPage] = useState(0)
  const [initialized, setInitialized] = useState(false)

  //Update User balances
  useEffect(() => {
    let isCancelled = false

    async function updateUserInfo() {
      const [
        allRegulations,
        totalDebt,
        twapPrice,
        // epoch
      ] = await Promise.all([
        getAllRegulations(ESDS.addr),
        getTotalDebt(ESDS.addr),
        getTwapPrice(),
        // getEpoch(ESDS.addr),
      ])

      if (!isCancelled) {
        setRegulations(
          allRegulations.map((reg: any) => {
            if (Number(reg.data.epoch) > 672) {
              reg.bootstrap = true
              reg.totalDebt = totalDebt
              reg.twapPrice = twapPrice
            }
            return reg
          })
        )
        setInitialized(true)
      }
    }

    updateUserInfo()
    const id = setInterval(updateUserInfo, 15000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [])

  return (
    <div
      className={styles.data_view}
      style={{ borderColor: currentTheme.border }}
    >
      <DataView
        fields={['Epoch', 'Δ Peg', 'Δ Redeemable', 'Δ Debt', 'Δ Bonded']}
        status={initialized ? 'default' : 'loading'}
        entries={regulations}
        entriesPerPage={10}
        page={page}
        onPageChange={setPage}
        renderEntry={renderEntry}
      />
    </div>
  )
}

export default RegulationHistory
