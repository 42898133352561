import React, { useEffect, useState } from 'react'
import {
  DataView,
  Button,
  IconCirclePlus,
  IconRefresh,
  IconCheck,
  IconLock,
  useTheme,
} from '@aragon/ui'
import BigNumberInput from '../common/BigNumberInput'
import { MaxButton } from '../common/index'

import {
  getEpoch,
  getBatchBalanceOfCoupons,
  getBatchBalanceOfCouponsUnderlying,
  getBatchCouponsExpiration,
  getCouponEpochs,
} from '../../utils/infura'
import { ESD, ESDS } from '../../constants/tokens'
import { formatBN, toBaseUnitBN, toTokenUnitsBN } from '../../utils/number'
import BigNumber from 'bignumber.js'
import { redeemCoupons, migrateCoupons } from '../../utils/web3'
import styles from './CouponMarket.module.css'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { useAccount } from 'wagmi'

type PurchaseHistoryProps = {
  user: string
  hideRedeemed: boolean
  showMyCoupons: boolean
  totalRedeemable: BigNumber
}

function PurchaseHistory({
  user,
  hideRedeemed,
  showMyCoupons,
  totalRedeemable,
}: PurchaseHistoryProps) {
  const currentTheme = useTheme()
  const [epochs, setEpochs] = useState([])
  const [page, setPage] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const [currentEpoch, setCurrentEpoch] = useState(0)
  const { address } = useAccount()

  //Update User balances
  useEffect(() => {
    if (user === '') return
    let isCancelled = false

    async function updateUserInfo() {
      try {
        const epochStr = await getEpoch(ESDS.addr)
        const epochsFromEvents = await getCouponEpochs(ESDS.addr, user)

        const epochNumbers = epochsFromEvents.map((e) => parseInt(e.epoch))

        const balanceOfCouponsPremium = await getBatchBalanceOfCoupons(
          ESDS.addr,
          user,
          epochNumbers
        )

        // const balanceOfCouponsPrincipal =
        //   await getBatchBalanceOfCouponsUnderlying(
        //     ESDS.addr,
        //     user,
        //     epochNumbers
        //   )

        const couponsExpirations = await getBatchCouponsExpiration(
          ESDS.addr,
          epochNumbers
        )

        let couponEpochs: any = []
        let last

        epochsFromEvents.forEach((epoch, i) => {
          epoch.principal = new BigNumber(0)
          epoch.premium = balanceOfCouponsPremium[i]
          epoch.expiration = couponsExpirations[i]

          const found = couponEpochs.find((coupon, i) => {
            if (coupon.epoch === epoch.epoch) {
              couponEpochs[i].coupons = couponEpochs[i].coupons.add(
                epoch.coupons
              )
              return true
            }
          })

          if (!found) {
            couponEpochs.push(epoch)
          }
        })

        couponEpochs = couponEpochs.reverse()

        // console.log(couponEpochs)

        // const couponEpochs = epochsFromEvents
        //   .map((epoch, i) => {
        //     // epoch.principal = new BigNumber(balanceOfCouponsPrincipal[i])
        //     epoch.principal = new BigNumber(0)
        //     epoch.premium = balanceOfCouponsPremium[i]
        //     epoch.expiration = couponsExpirations[i]
        //     return epoch
        //   })
        //   .reverse()

        if (!isCancelled) {
          // @ts-ignore
          setEpochs(couponEpochs)
          setCurrentEpoch(parseInt(epochStr, 10))
          setInitialized(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    updateUserInfo()
    const id = setInterval(updateUserInfo, 15000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [user, totalRedeemable])

  let filteredEpochs = epochs

  if (hideRedeemed) {
    filteredEpochs = epochs.filter(
      (epoch: any) => !epoch.principal.isZero() || !epoch.premium.isZero()
    )
  }
  if (showMyCoupons) {
    filteredEpochs = epochs.filter((epoch: any) => {
      return epoch.account === address?.toLowerCase()
    })
  }

  return (
    <div
      className={styles.data_view_custom}
      style={{ borderColor: currentTheme.border }}
    >
      <DataView
        fields={[
          'Epoch',
          'Purchased',
          //  'Principal',
          'Balance',
          'Expires',
          '',
        ]}
        status={initialized ? 'default' : 'loading'}
        // @ts-ignore
        entries={filteredEpochs}
        entriesPerPage={10}
        page={page}
        onPageChange={setPage}
        renderEntry={(epoch) => {
          return [
            epoch.epoch,
            formatBN(toTokenUnitsBN(epoch.coupons, ESD.decimals), 2),
            // formatBN(toTokenUnitsBN(epoch.principal, ESD.decimals), 2),
            formatBN(toTokenUnitsBN(epoch.premium, ESD.decimals), 2),
            epoch.expiration.toString(),
            <CouponAction
              epoch={currentEpoch}
              coupon={epoch}
              totalRedeemable={totalRedeemable}
            />,
          ]
        }}
      />
    </div>
  )
}

type CouponActionProps = {
  epoch: number
  coupon: any
  totalRedeemable: BigNumber
}

function CouponAction({ epoch, coupon, totalRedeemable }: CouponActionProps) {
  const isRedeemable = !coupon.premium.isZero() && !totalRedeemable.isZero()
  const isExpired = coupon.expiration <= epoch

  const [burnAmount, setBurnAmount] = useState(new BigNumber(0))

  return (
    <>
      {
        // /* pre-EIP-16 style coupons */
        // coupon.principal.isZero() && !coupon.premium.isZero() ? (
        //   <Button
        //     icon={<IconRefresh />}
        //     label="Migrate"
        //     onClick={() => migrateCoupons(ESDS.addr, coupon.epoch)}
        //   />
        // ) : /* already redeemed coupons */
        // coupon.principal.isZero() ? (
        //   <Button icon={<IconCheck />} label="Redeemed" disabled={true} />
        // ) :
        /* redeemable coupons */
        isExpired ? (
          'Expired'
        ) : (
          <>
            <div>
              <BigNumberInput
                adornment="T"
                value={burnAmount}
                setter={(value) => {
                  setBurnAmount(value)
                }}
              />
              <MaxButton
                onClick={() => {
                  setBurnAmount(new BigNumber(formatEther(coupon.premium)))
                }}
              />
            </div>
            <Button
              icon={isRedeemable ? <IconCirclePlus /> : <IconLock />}
              label="Redeem"
              onClick={() =>
                redeemCoupons(
                  ESDS.addr,
                  coupon.epoch,
                  parseEther(burnAmount.toString())
                )
              }
              disabled={!isRedeemable}
            />
          </>
        )
      }
    </>
  )
}

export default PurchaseHistory
