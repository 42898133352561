import React from 'react'
import { LinkBase, useTheme } from '@aragon/ui'
import ChangeModeButton from './SwitchTheme'

type FooterProps = {
  updateTheme: Function
  theme: string
  hasWeb3: boolean
  className: string
}

function Footer({ updateTheme, theme, hasWeb3, className }: FooterProps) {
  const currentTheme = useTheme()

  return (
    <>
      <div
        className={className}
        style={{
          borderTop: '1px solid ' + currentTheme.border,
          backgroundColor: currentTheme.surface,
          textAlign: 'center',
          position: 'fixed',
          left: '0',
          bottom: '0',
          height: 'auto',
          width: '100%',
          fontSize: '14px',
        }}
      >
        <div
          style={{
            maxWidth: '1100px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div style={{ padding: '2%', display: 'flex' }}>
            <div
              style={{
                width: '50%',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <a
                href="https://etherscan.io/address/0x6967299e9F3d5312740Aa61dEe6E9ea658958e31"
                target="_blank"
                style={{ whiteSpace: 'nowrap' }}
              >
                T token
              </a>
              <a
                href="https://etherscan.io/address/0x883B01205b938EC7268286d13Cb40a2045D2E78F"
                target="_blank"
              >
                Forge
              </a>
              <a
                href="https://etherscan.io/address/0x87033b47CA6AFa615Af89281240BB32DF5de3dEA"
                target="_blank"
              >
                Pool
              </a>
              <a
                href="https://etherscan.io/address/0x473551a3bb6abbb68e14c0b7b9f8012ae97f0ff5"
                target="_blank"
              >
                Oracle
              </a>
              <a
                href="https://etherscan.io/address/0xA77d09743F77052950C4eb4e6547E9665299BecD"
                target="_blank"
              >
                Metapool
              </a>
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                height: '18px',
                marginTop: '15px',
                marginBottom: '15px',
                marginRight: '15px',
              }}
            >
              UNSTOPPABLE, TRUSTLESS, DECENTRALIZED
            </div>
            <div style={{ width: '5%', textAlign: 'right', marginTop: '4px' }}>
              <ChangeModeButton
                hasWeb3={hasWeb3}
                theme={theme}
                updateTheme={updateTheme}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type FooterLinkProp = {
  icon: any
  href: string
}

export function FooterLink({ icon, href }: FooterLinkProp) {
  return (
    <LinkBase href={href} style={{ marginLeft: '8px', marginRight: '8px' }}>
      <span style={{ fontSize: 32 }}>{icon}</span>
    </LinkBase>
  )
}

export default Footer
