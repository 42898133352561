import React, { useState } from 'react'
import {
  Box,
  Button,
  IconCirclePlus,
  IconCircleMinus,
  IconLock,
} from '@aragon/ui'
import BigNumber from 'bignumber.js'
import { BalanceBlock, MaxButton } from '../common/index'
import { approve, deposit, withdraw } from '../../utils/web3'
import { isPos, toBaseUnitBN } from '../../utils/number'
import { ESD, ESDS } from '../../constants/tokens'
import { MAX_UINT256 } from '../../constants/values'
import BigNumberInput from '../common/BigNumberInput'
import styles from './Wallet.module.css'

type WithdrawDepositProps = {
  user: string
  balance: BigNumber
  allowance: BigNumber
  stagedBalance: BigNumber
  status: number
}

function WithdrawDeposit({
  user,
  balance,
  allowance,
  stagedBalance,
  status,
}: WithdrawDepositProps) {
  const [depositAmount, setDepositAmount] = useState(new BigNumber(0))
  const [withdrawAmount, setWithdrawAmount] = useState(new BigNumber(0))

  return (
    <Box className={styles.box_custom_style} heading="Stage">
      {allowance.comparedTo(MAX_UINT256) === 0 ? (
        <div className={styles.wrapper}>
          {/* total Issued */}
          <div style={{ flexBasis: '32%' }}>
            <BalanceBlock asset="Staged" balance={stagedBalance} suffix={'T'} />
          </div>
          {/* Deposit Døllar into DAO */}
          <div className={styles.button_wrapper}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '55%', minWidth: '6em' }}>
                <>
                  <BigNumberInput
                    adornment="T"
                    value={depositAmount}
                    setter={setDepositAmount}
                    disabled={status !== 0}
                  />
                  <MaxButton
                    onClick={() => {
                      setDepositAmount(balance)
                    }}
                  />
                </>
              </div>
              <div style={{ width: '45%', minWidth: '6em' }}>
                <Button
                  wide
                  icon={status === 0 ? <IconCirclePlus /> : <IconLock />}
                  label="Deposit"
                  onClick={() => {
                    deposit(
                      ESDS.addr,
                      toBaseUnitBN(depositAmount, ESD.decimals)
                    )
                  }}
                  disabled={
                    status === 1 ||
                    !isPos(depositAmount) ||
                    depositAmount.isGreaterThan(balance)
                  }
                />
              </div>
            </div>
            <div style={{ flexBasis: '2%' }} />
            {/* Withdraw Døllar from DAO */}
            <div style={{ display: 'flex' }}>
              <div style={{ width: '55%', minWidth: '7em' }}>
                <>
                  <BigNumberInput
                    adornment="T"
                    value={withdrawAmount}
                    setter={setWithdrawAmount}
                    disabled={status !== 0}
                  />
                  <MaxButton
                    onClick={() => {
                      setWithdrawAmount(stagedBalance)
                    }}
                  />
                </>
              </div>
              <div style={{ width: '45%', minWidth: '7em' }}>
                <Button
                  wide
                  icon={status === 0 ? <IconCircleMinus /> : <IconLock />}
                  label="Withdraw"
                  onClick={() => {
                    withdraw(
                      ESDS.addr,
                      toBaseUnitBN(withdrawAmount, ESD.decimals)
                    )
                  }}
                  disabled={
                    status === 1 ||
                    !isPos(withdrawAmount) ||
                    withdrawAmount.isGreaterThan(stagedBalance)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          {/* total Issued */}
          <div style={{ flexBasis: '32%' }}>
            <BalanceBlock asset="Staged" balance={stagedBalance} suffix={'T'} />
          </div>
          <div style={{ flexBasis: '35%' }} />
          {/* Approve DAO to spend Døllar */}
          <div className={styles.button_wrapper}>
            <Button
              wide
              icon={<IconCirclePlus />}
              label="Approve"
              onClick={() => {
                approve(ESD.addr, ESDS.addr)
              }}
              disabled={user === ''}
            />
          </div>
        </div>
      )}
      <div style={{ width: '100%', paddingTop: '2%', textAlign: 'center' }}>
        <span style={{ opacity: 0.5 }}>
          Get your T tokens{' '}
          <a
            href="https://curve.fi/#/ethereum/pools/factory-v2-229/swap"
            target="_blank"
          >
            here
          </a>{' '}
          by swapping from DAI, USDC, USDT or 3CRV.
        </span>
      </div>
    </Box>
  )
}

export default WithdrawDeposit
