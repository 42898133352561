import React from 'react';

import {
  Header,
} from '@aragon/ui';

import styles from './common-styles.module.css'

type IconHeaderProps = {
  icon: any,
  text: string
}

function IconHeader({ icon, text }: IconHeaderProps) {
  return (
    <>
      <div className={styles.icon_wrapper}>
        <div style={{ marginRight: '2%', fontSize: 48 }}>
          {icon}
        </div>
        <div>
          <Header className={styles.icon_header} primary={text} />
        </div>
      </div>
    </>
  );
}

export default IconHeader;
