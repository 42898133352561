import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, LinkBase, Tag } from '@aragon/ui'
import EpochBlock from '../common/EpochBlock'
import styles from './HomePage.module.css'
import { getStats } from '../../utils/infura'
import { delineate } from '../../utils/number'
import { EPOCH_START } from '../../constants/values'

function epochformatted() {
  const epochStart = EPOCH_START
  const epochPeriod = 1800
  const hour = 60 * 60
  const minute = 60
  const unixTimeSec = Math.floor(Date.now() / 1000)

  let epochRemainder = unixTimeSec - epochStart
  const epoch = Math.floor(epochRemainder / epochPeriod)
  epochRemainder -= epoch * epochPeriod
  const epochHour = Math.floor(epochRemainder / hour)
  epochRemainder -= epochHour * hour
  const epochMinute = Math.floor(epochRemainder / minute)
  epochRemainder -= epochMinute * minute
  return `${epoch}-${
    epochMinute > 9 ? epochMinute : '0' + epochMinute.toString()
  }:${epochRemainder > 9 ? epochRemainder : '0' + epochRemainder.toString()}`
}

type HomePageProps = {
  user: string
}

function Epoch() {
  const [epochTime, setEpochTime] = useState('0-00:00')
  useEffect(() => {
    const id = setInterval(() => {
      setEpochTime(epochformatted())
    }, 1000)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(id)
    }
  })
  return (
    <Box className={styles.enlarge_content}>
      <EpochBlock epoch={epochTime} />
    </Box>
  )
}

function HomePage({ user }: HomePageProps) {
  const history = useHistory()
  // const [epoch, setEpoch] = useState(0)
  const [price, setPrice] = useState('...')
  const [forgeYield, setForgeYield] = useState('...')
  const [poolYield, setPoolYield] = useState('...')
  const [forgeTotal, setForgeTotal] = useState('...')
  const [poolTotal, setPoolTotal] = useState('...')
  const [totalTvl, setTotalTvl] = useState('...')

  useEffect(() => {
    let isCancelled = false

    async function updateUserInfo() {
      try {
        const stats: any = await getStats()
        setPrice(stats.tPrice)
        setForgeYield(stats.forgeYield)
        setPoolYield(stats.poolYield)
        setForgeTotal(stats.forgeTvl)
        setPoolTotal(stats.poolTvl)
        setTotalTvl(stats.totalTvl)
      } catch (error) {
        console.log(error)
      }
    }

    updateUserInfo()
    const id = setInterval(updateUserInfo, 60000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>T Price</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {'$' + Number(price).toFixed(3)}
          </div>
        </Box>

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Total TVL</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {'$' + totalTvl}
          </div>
        </Box>

        <Epoch />

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Forge Epoch Yield</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {delineate(Number(forgeYield).toFixed(3)) + '%'}
          </div>
        </Box>

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Forge APY</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {delineate((Number(forgeYield) * 48 * 365).toFixed(3)) + '%'}
          </div>
        </Box>

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Forge TVL</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {'$' + delineate(Number(forgeTotal).toFixed(2))}
          </div>
        </Box>

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Pool Epoch Yield</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {delineate(Number(poolYield).toFixed(3)) + '%'}
          </div>
        </Box>

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Pool APR</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {delineate((Number(poolYield) * 48 * 365).toFixed(3)) + '%'}
          </div>
        </Box>

        <Box>
          <div style={{ fontSize: 16, padding: 3 }}>Pool TVL</div>
          <div
            style={{
              fontSize: 24,
              padding: 3,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {'$' + delineate(Number(poolTotal).toFixed(2))}
          </div>
        </Box>

        <MainButton
          title="Forge"
          description="Earn rewards for bonding."
          icon={<i className="fas fa-dot-circle" />}
          onClick={() => {
            history.push('/forge/')
          }}
        />

        <MainButton
          title="LP Rewards"
          description="Earn rewards for providing liquidity."
          icon={<i className="fas fa-parachute-box" />}
          onClick={() => {
            history.push('/pool/')
          }}
        />

        <MainButton
          title="Regulation"
          description="Network supply regulation statistics."
          icon={<i className="fas fa-chart-area" />}
          onClick={() => {
            history.push('/regulation/')
          }}
        />
        <MainButton
          title="Epoch"
          description="Advance epoch."
          icon={<i className="fas fa-poll" />}
          onClick={() => {
            history.push('/epoch/')
          }}
        />

        <MainButton
          title="Trade"
          description="Trade T tokens."
          icon={<i className="fas fa-exchange-alt" />}
          onClick={() => {
            history.push('/trade/')
          }}
        />

        <MainButton
          title="Coupons"
          description="Purchase and redeem coupons."
          icon={<i className="fas fa-ticket-alt" />}
          onClick={() => {
            history.push('/coupons/')
          }}
        />
      </div>
    </>
  )
}

type MainButtonPropx = {
  title: string
  description: string
  icon: any
  onClick: Function
  tag?: string
}

function MainButton({
  title,
  description,
  icon,
  onClick,
  tag,
}: MainButtonPropx) {
  return (
    <LinkBase onClick={onClick} style={{ width: '100%' }}>
      <Box>
        <div style={{ padding: 10, fontSize: 18 }}>
          {title}
          {tag ? <Tag>{tag}</Tag> : <></>}
        </div>
        <span style={{ fontSize: 48 }}>{icon}</span>
        {/*<img alt="icon" style={{ padding: 10, height: 64 }} src={iconUrl} />*/}
        <div style={{ paddingTop: 5, opacity: 0.5 }}> {description} </div>
      </Box>
    </LinkBase>
  )
}

export default HomePage
