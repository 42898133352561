import React, { useState, useEffect } from 'react'
import { Header } from '@aragon/ui'
import { useParams } from 'react-router-dom'

import {
  getCouponPremium,
  getTokenAllowance,
  getTokenBalance,
  getTokenTotalSupply,
  getTotalCoupons,
  getTotalCouponsUnderlying,
  getTotalDebt,
  getTotalRedeemable,
} from '../../utils/infura'
import { ESD, ESDS } from '../../constants/tokens'
import CouponMarketHeader from './Header'
import { toTokenUnitsBN } from '../../utils/number'
import BigNumber from 'bignumber.js'
import PurchaseCoupons from './PurchaseCoupons'
import PurchaseHistory from './PurchaseHistory'
import ModalWarning from './ModalWarning'
import IconHeader from '../common/IconHeader'
import { getPreference, storePreference } from '../../utils/storage'
import { CheckBox } from '../common'
import commonStyles from '../common/common-styles.module.css'
import styles from './CouponMarket.module.css'

const ONE_COUPON = new BigNumber(10).pow(18)

function CouponMarket({ user }: { user: string }) {
  const { override } = useParams()
  if (override) {
    user = override
  }

  const storedHideRedeemed = getPreference('hideRedeemedCoupons', '0')
  const storedMyCoupons = getPreference('myCouponsOnly', '0')

  const [balance, setBalance] = useState(new BigNumber(0))
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const [supply, setSupply] = useState(new BigNumber(0))
  const [coupons, setCoupons] = useState(new BigNumber(0))
  const [redeemable, setRedeemable] = useState(new BigNumber(0))
  const [couponPremium, setCouponPremium] = useState(new BigNumber(0))
  const [debt, setDebt] = useState(new BigNumber(0))
  const [hideRedeemed, setHideRedeemed] = useState(storedHideRedeemed === '1')
  const [showMyCoupons, setShowMyCoupons] = useState(storedMyCoupons === '1')

  useEffect(() => {
    if (user === '') {
      setBalance(new BigNumber(0))
      setAllowance(new BigNumber(0))
      return
    }
    let isCancelled = false

    async function updateUserInfo() {
      try {
        const [balanceStr, allowanceStr] = await Promise.all([
          getTokenBalance(ESD.addr, user),
          getTokenAllowance(ESD.addr, user, ESDS.addr),
        ])

        const userBalance = toTokenUnitsBN(balanceStr, ESD.decimals)

        if (!isCancelled) {
          setBalance(new BigNumber(userBalance))
          setAllowance(new BigNumber(allowanceStr.toString()))
        }
      } catch (error) {
        console.log(error)
      }
    }
    updateUserInfo()
    const id = setInterval(updateUserInfo, 15000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [user])

  useEffect(() => {
    let isCancelled = false

    async function updateUserInfo() {
      try {
        const [
          supplyStr,
          debtStr,
          couponsPremiumStr,
          // couponsPrincipalStr,
          redeemableStr,
        ] = await Promise.all([
          getTokenTotalSupply(ESD.addr),
          getTotalDebt(ESDS.addr),
          getTotalCoupons(ESDS.addr),
          // getTotalCouponsUnderlying(ESDS.addr),
          getTotalRedeemable(ESDS.addr),
        ])

        const totalSupply = toTokenUnitsBN(supplyStr, ESD.decimals)
        const totalDebt = toTokenUnitsBN(debtStr, ESD.decimals)
        const totalCoupons = toTokenUnitsBN(couponsPremiumStr, ESD.decimals)
        const totalRedeemable = toTokenUnitsBN(redeemableStr, ESD.decimals)

        if (!isCancelled) {
          setSupply(new BigNumber(totalSupply))
          setDebt(new BigNumber(totalDebt))
          setCoupons(new BigNumber(totalCoupons))
          setRedeemable(new BigNumber(totalRedeemable))

          if (totalDebt.isGreaterThan(new BigNumber(1))) {
            const couponPremiumStr = await getCouponPremium(
              ESDS.addr,
              ONE_COUPON
            )
            setCouponPremium(toTokenUnitsBN(couponPremiumStr, ESD.decimals))
          } else {
            setCouponPremium(new BigNumber(0))
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    updateUserInfo()
    const id = setInterval(updateUserInfo, 15000)

    // eslint-disable-next-line consistent-return
    return () => {
      isCancelled = true
      clearInterval(id)
    }
  }, [user])

  return (
    <>
      <ModalWarning />

      <IconHeader
        icon={<i className="fas fa-ticket-alt" />}
        text="Coupon Market"
      />

      <CouponMarketHeader
        debt={debt}
        supply={supply}
        coupons={coupons}
        premium={couponPremium}
        redeemable={redeemable}
      />

      <Header className={commonStyles.icon_header} primary="Purchase" />

      <PurchaseCoupons
        user={user}
        allowance={allowance}
        balance={balance}
        debt={debt}
      />

      <div className={styles.coupon_header}>
        <Header className={commonStyles.icon_header} primary="Coupons" />
        <div
          style={{ marginLeft: 'auto', alignSelf: 'flex-end', display: 'flex' }}
        >
          <CheckBox
            text="My Coupons"
            onCheck={(checked) => {
              storePreference('myCouponsOnly', checked ? '1' : '0')
              setShowMyCoupons(checked)
            }}
            checked={showMyCoupons}
          />
          <CheckBox
            text="Hide Redeemed"
            onCheck={(checked) => {
              storePreference('hideRedeemedCoupons', checked ? '1' : '0')
              setHideRedeemed(checked)
            }}
            checked={hideRedeemed}
          />
        </div>
      </div>

      <PurchaseHistory
        user={user}
        hideRedeemed={hideRedeemed}
        showMyCoupons={showMyCoupons}
        totalRedeemable={redeemable}
      />
    </>
  )
}

export default CouponMarket
